"use client"
import React, { useRef, useEffect } from "react"

const GrassLoader = ({
  isLandingPage,
  className = "",
}: {
  isLandingPage?: boolean
  className?: string
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const stack = useRef<(() => void)[]>([])

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext("2d")
    if (!ctx) return

    const w = window.innerWidth
    const h = window.innerHeight

    canvas.width = w
    canvas.height = h

    const drawer = function () {
      //   ctx.fillStyle = "#ffffff"
      //   ctx.fillRect(0, 0, w, h)
      ctx.clearRect(0, 0, w, h)
      stack.current.forEach(function (el) {
        el()
      })
      requestAnimationFrame(drawer)
    }

    const anim = function () {
      let x = 0
      const maxTall = Math.random() * 100 + 200
      const maxSize = Math.random() * 10 + 5
      const speed = Math.random() * 1
      const position = Math.random() * w - w / 2
      const c = (l?: number, u?: number): number =>
        Math.round(Math.random() * (u || 255) + (l || 0))
      const color = `rgb(${c(60, 10)},${c(201, 50)},${c(120, 50)})`

      return function () {
        const deviation = Math.cos(x / 30) * Math.min(x / 40, 50)
        const tall = Math.min(x / 2, maxTall)
        const size = Math.min(x / 50, maxSize)
        x += speed
        ctx.save()
        // @ts-ignore
        ctx.strokeWidth = 10
        ctx.translate(w / 2 + position, h)
        ctx.fillStyle = color
        ctx.beginPath()
        ctx.lineTo(-size, 0)
        ctx.quadraticCurveTo(-size, -tall / 2, deviation, -tall)
        ctx.quadraticCurveTo(size, -tall / 2, size, 0)
        ctx.fill()
        ctx.restore()
      }
    }

    for (let x = 0; x < 400; x++) {
      stack.current.push(anim())
    }

    drawer()

    // Cleanup function to cancel the animation frame on component unmount
    return () => {
      const id = requestAnimationFrame(drawer)
      cancelAnimationFrame(id)
    }
  }, [])

  return (
    <canvas
      className={`block m-0 absolute ${
        isLandingPage ? "bottom-[-100px] lg:bottom-0" : "bottom-0"
      } left-0 pointer-events-none z-10 ${className}`}
      ref={canvasRef}
    ></canvas>
  )
}

export default GrassLoader
